<template>
  <div>
    <b-card no-body class="mb-0">
      <b-row class="p-1">
        <b-col>
          <b-form-group :label="$t('Ref')" label-for="name">
            <b-form-input id="name" v-model="refRef" debounce="500" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group :label="$t('Select client')" label-for="client">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="clients"
              v-model="clientRef"
              label="name"
              input-id="client"
              @search="fetchClientOptions"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group :label="$t('Select recoverer')" label-for="client">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="recoverers"
              v-model="recovererRef"
              label="name"
              input-id="client"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-table
        ref="refSituationListTable"
        class="position-relative"
        :items="fetchSituations"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :empty-text="$t('No matching records found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Actions -->
        <template #cell(amount)="data"> {{ data.item.amount }} DH </template>
        <!-- Column: Actions -->
        <template #cell(reste)="data"> {{ data.item.reste }} DH </template>
        <!-- Column: Actions -->
        <template #cell(paid_reste)="data">
          {{ data.item.paid_reste }} DH
        </template>
        <template #cell(actions)="data">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="btn-icon mr-1"
            @click="consulteSituation(data)"
          >
            <feather-icon icon="ClipboardIcon" size="16" />
          </b-button>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="btn-icon"
            @click="printQuotation(data)"
          >
            <feather-icon icon="PrinterIcon" size="16" />
          </b-button>

          <b-modal
            :ref="'consule' + data.index + 'Modal'"
            hide-footer
            centered
            size="xl"
            title="Consulte"
          >
            <b-table
              class="position-relative"
              :items="situationLines"
              responsive
              :fields="['bl_ref', 'bl_date', 'amount', 'reste', 'created_at']"
            >
              <template #cell(amount)="data">
                {{ data.item.amount }} DH
              </template>
              <template #cell(reste)="data">
                {{ data.item.reste }} DH
              </template>
            </b-table>
          </b-modal>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{
              $t("Pagination text", [dataMeta.from, dataMeta.to, dataMeta.of])
            }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalSituations"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BForm,
  BFormGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { avatarText } from "@core/utils/filter";
import useSituationsList from "./useSituationList";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import instance from "@/libs/axios";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BForm,
    BFormGroup,

    ToastificationContent,
    vSelect,
  },
  async mounted() {
    try {
      const res = await Promise.all([
        instance.get("/parameters/clients/"),
        instance.get("/recoveries/users/"),
      ]);
      this.clients = res[0].data.results;
      this.recoverers = res[1].data;
    } catch (err) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("Error"),
          text: err.message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    }
  },

  data() {
    return {
      clients: [],
      recoverers: [],
      situationLines: [],
    };
  },

  methods: {
    showFile(blob) {
      const binaryString = window.atob(blob);
      const binaryLen = binaryString.length;
      const bytes = new Uint8Array(binaryLen);
      for (let i = 0; i < binaryLen; i++) {
        const ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      var newBlob = new Blob([bytes], { type: "application/pdf" });

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }

      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement("a");
      link.href = data;
      link.download = "situation.pdf";
      link.click();
      setTimeout(function () {
        window.URL.revokeObjectURL(data);
      }, 100);
    },
    async printQuotation(data) {
      try {
        const res = await instance.patch(
          `recoveries/situation/${data.item.id}`
        );

        this.showFile(res.data);
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    async consulteSituation(data) {
      try {
        const res = await instance.get(`/recoveries/situation/${data.item.id}`);

        this.situationLines = res.data;
        this.$refs[`consule${data.index}Modal`].show();
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    fetchClientOptions(search, loading) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(async () => {
        try {
          loading(true);
          const res = await instance.get("/parameters/clients/", {
            params: { search },
          });
          this.clients = res.data.results;
        } catch (err) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Error"),
              text: err.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } finally {
          loading(false);
        }
      }, 500);
    },
  },

  setup() {
    const {
      fetchSituations,
      tableColumns,
      perPage,
      currentPage,
      totalSituations,
      dataMeta,
      perPageOptions,
      searchQuery,
      isSortDirDesc,
      refSituationListTable,
      refetchData,
      refRef,
      clientRef,
      createdByRef,
      recovererRef,
    } = useSituationsList();

    return {
      fetchSituations,
      tableColumns,
      perPage,
      currentPage,
      totalSituations,
      dataMeta,
      perPageOptions,
      searchQuery,
      isSortDirDesc,
      refSituationListTable,
      refetchData,
      // Filter
      avatarText,
      refRef,
      clientRef,
      createdByRef,
      recovererRef,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
